.error-field {
  font-size: 9px;
  color: #eb7e7e !important;
  margin: 0;
  padding: 0;
}

.invalid-field {
  border: solid 1px #eb7e7e;
}

.loading-mask {
  display: flex;
  justify-content: center;
}